import React from "react";
import piechart from '../../assets/images/piechart.svg'
import biggradient from '../../assets/images/big-gradient.svg'
const Tokenomics = () => {
  return (
    <div id="tokenSection" className="tokenomics position-relative  tw-my-14">
      {/* <h1 className=" tw-text-white  tw-text-center tw-font-semibold tw-text-4xl">
        TOKENOMICS
      </h1> */}

      <div className="container ">
        <div className="row">
          <div className="col-md-12 tw-mx-auto">
            <h1 className="h-text-1 mb-3">Tokenomics</h1>
          <img src={piechart} className=" tw-w-full" />
          </div>
        </div>
      </div>
      <div className="left-gradient">
                    <img src={biggradient} width={904} height={904} alt="gradient" className="gradient-img"/>
                  </div>
                  <div className="right-gradient">
                    <img src={biggradient} width={904} height={904} alt="gradient" className="gradient-img"/>
                  </div>

    </div>
  );
};

export default Tokenomics;
