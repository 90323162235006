import React from 'react'
import metaverse from '../../assets/images/metamask.svg';
import trusst from '../../assets/images/trust-wallet.svg';
import exodus from '../../assets/images/exodus.svg';
import safepal from '../../assets/images/safepal.svg';
import walletconnect from '../../assets/images/walletconnect.svg';
import pinkgradient from '../../assets/images/pinkgradient.svg'

const Brands = () => {
  return (
    <div className='wallet-option tw-z-50 mb-4'>
        <div className='container'>
       <div className='desktop-view'>
      <div className='connect-option'>
        <div>
            <img src={metaverse} width={211} height={59} alt='wallet option' title='metaverse option' className=''/>
        </div>
        <div>
            <img src={trusst} width={211} height={59} alt='wallet option' title='metaverse option'/>
        </div>
        <div>
            <img src={walletconnect} width={211} height={59} alt='wallet option' title='metaverse option'/>
        </div>
        <div>
            <img src={exodus} width={211} height={59} alt='wallet option' title='metaverse option'/>
        </div>
        <div>
            <img src={safepal} width={211} height={59} alt='wallet option' title='metaverse option'/>
        </div>
   
      </div>
      </div>
      <div className='mobile-view'>

<div className='row'>
  <div className='col-6'>
  <img src={metaverse} width={211} height={59} alt='wallet option' title='metaverse option' className='wallet-img'/>

  </div>
  <div className='col-6'>
  <img src={trusst} width={211} height={59} alt='wallet option' title='metaverse option'className='wallet-img'/>
    
    </div>
</div>

<div className='row'>
  <div className='col-6'>
  <img src={walletconnect} width={211} height={59} alt='wallet option' title='metaverse option' className='wallet-img'/>

  </div>
  <div className='col-6'>
  <img src={exodus} width={211} height={59} alt='wallet option' title='metaverse option' className='wallet-img'/>
    
    </div>
</div>

<div className='row'>

  <div className='col-6'>
  <img src={safepal} width={211} height={59} alt='wallet option' title='metaverse option' className='wallet-img'/>
    
    </div>
</div>

       


      </div>
    </div>
  
    </div>
  )
}

export default Brands