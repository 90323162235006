import React from 'react';
import monkey1 from '../../../assets/images/cat.svg';
import monkey2 from '../../../assets/images/nft1.svg';
import monkey3 from '../../../assets/images/nft2.svg';
import monkey4 from '../../../assets/images/nft3.svg';
import monkey5 from '../../../assets/images/nft4.svg';

const cardData = [
  {
    img:monkey1,
    title: "COOLGUYZZ",
    subtitle: "Coolguyzz.io",
    eth: "1.5 ETH",
    usd: "$2045.12",
    floorPrice: "Floor Price",
    change: "-12.45%",
    isGain: false,
  },
  {
    img:monkey2,
    title: "COOLGUYZZ",
    subtitle: "Coolguyzz.io",
    eth: "1.5 ETH",
    usd: "$2045.12",
    floorPrice: "Floor Price",
    change: "-12.45%",
    isGain: false,
  },
  {
    img:monkey3,
    title: "COOLGUYZZ",
    subtitle: "Coolguyzz.io",
    eth: "1.5 ETH",
    usd: "$2045.12",
    floorPrice: "Floor Price",
    change: "+12.45%",
    isGain: true,
  },
  {
    img:monkey4,
    title: "COOLGUYZZ",
    subtitle: "Coolguyzz.io",
    eth: "1.5 ETH",
    usd: "$2045.12",
    floorPrice: "Floor Price",
    change: "+12.45%",
    isGain: true,
  },
  {
    img:monkey5,
    title: "COOLGUYZZ",
    subtitle: "Coolguyzz.io",
    eth: "1.5 ETH",
    usd: "$2045.12",
    floorPrice: "Floor Price",
    change: "-12.45%",
    isGain: false,
  },
  // Add more cards as needed
];

const ArtCard = () => {
  return (
    <div className="main-nft-card">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="nft-card">
              {cardData.map((card, index) => (
                <div className="card" key={index}>
                  <img
                    src={card.img}
                    width={211}
                    height={208}
                    alt="monkey1 img"
                    title="monkey card img"
                    className="card-img-top"
                  />
                  <div className="card-body w-100 mt-2">
                    <h5 className="card-title">{card.title}</h5>
                    {/* <p className="card-text mt-1">{card.subtitle}</p> */}
                    <div className="d-flex justify-content-between align-items-center w-100 mt-2">
                      <div>
                        <h4>{card.eth}</h4>
                      </div>
                      <div>
                        <h4>{card.usd}</h4>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mt-1">
                      <div>
                        <p>{card.floorPrice}</p>
                      </div>
                      <div>
                        <p className={card.isGain ? "gain" : "loss"}>{card.change}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtCard;
