import React from 'react'
import king from '../../assets/images/king.svg'
import ast from '../../assets/images/ast.svg'

const VissionMission = () => {
    return (
        <div className='vsms'>
            <div className='container'>
                <div className='vission-div'>
                    <h1 className="h-text-1">Our Vision</h1>

                    <p className="p-text-1">
                        At TIKDOGS, we envision a trusted platform that prioritizes transparency and community engagement. We aim to empower pet owners and their furry friends through innovative technology, fostering a safe environment where dogs can showcase their talents, earn rewards, and enhance the joy of pet ownership for everyone!
                    </p>
                </div>
                <div className='vsms-img-div'>
                    <div >
                        <img src={king} width={466} height={364} alt='king-dog' title='vission ' />
                    </div>
                    <div>
                        <img src={ast} width={364} height={364} alt='ast-dog' title='Mission' />

                    </div>
                </div>
                <div className='mission-div'>
                    <h1 className="h-text-1">Our Mission</h1>

                    <p className="p-text-1">
                    At TIKDOGS, our mission is to empower dog owners to achieve financial success while supporting charitable causes. By enabling pets to earn “TIKS” through their creativity—exchangeable for cash—we create opportunities for owners to grow wealth and thrive, ensuring a prosperous future for both pets and their dedicated humans.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VissionMission
