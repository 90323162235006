import React, { useEffect, useState } from "react";
import { FaCar } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

import { FaAngleDown } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import Button from "../Button";
import { MdMenu } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";


import { useWeb3Modal,useWeb3ModalTheme,use } from '@web3modal/wagmi/react'
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import headertop from '../../assets/images/header-top.svg'


const Header = ({setOpen,open1}) => {
  
  const [holdersDropdownOpen, setHoldersDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);

  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const [scrollBackground, setScrollBackground] = useState(false);



  const { open, close } = useWeb3Modal()
  const { isConnected,isDisconnected,chain } = useAccount()
  const { address } = useAccount();



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollBackground(true);
      } else {
        setScrollBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* <div class="header-top-div">
 <img src={headertop} width={1284} height={24} alt="header-top " title="this division comes top of header" className="color-div"/>
  </div> */}
      <nav
        className={`tw-top-[2px] md:tw-top-0 tw-w-full tw-z-50 tw-bg-[rgba(103,96,96,0.17)]  ${
          scrollBackground ? "tw-backdrop-blur  tw-fixed" : "tw-backdrop-blur"
        } sm:tw-relative md:tw-fixed `}
      >
        <div className="tw-flex tw-items-center tw-font-medium tw-h-[5rem]  container tw-mx-auto tw-justify-between">
          <div className="">
            <Link to="/" style={{textDecoration:"none"}}>
              <img
                src={require("../../assets/images/logo.png")}
                className="tw-object-contain tw-w-[50px] md:tw-w-[74px]"
                alt="Logo"
              />
            </Link>
          </div>
          {/* <div className="mobile-view">
<Button
                  onClick={open}
                  className={'mobile-connect-wallet-btn tw-rounded-[30px]'}
                  label={
                    isConnected
                      ? `${address.slice(0, 4)}....${address.slice(-4)}`
                      : "Connect Wallet"
                  }
                />
</div> */}

          <ul className="header-gap lg:tw-flex tw-hidden tw-items-center tw-font-[Poppins]    mb-0">
            <li>
              <Link className="header-links" to={"/"}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "aboutSection")}
                className="header-links"
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "tokenSection")}
                className="header-links"
              >
                Tokenomics
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "roadSection")}
                className="header-links"
              >
                Road Map
              </Link>
            </li>
            <li>
              <Link
                to={"/"}
                onClick={() => handleNavigate("/", "teamSection")}
                className="header-links"
              >
                Team
              </Link>
            </li>
            <li>
              <Link to={"/staking"} className="header-links">
                Staking
              </Link>
            </li>
            <li>
              <a href={"https://mlm.tikdogs.com/"} className="header-links">
                MLM
              </a>
            </li>
            {/* <li>
            <Link
              to={"/"}
              onClick={() => handleNavigate("/", "teamSection")}
              className="tw-text-white"
            >
              Team
            </Link>
          </li> */}
          </ul>
          <Button
            className=" tw-rounded-[30px]  connect-wallet-btn"
            onClick={() => open()}
            label={
              !isConnected
                ? "Connect Wallet"
                : address.slice(0, 4) + "...." + address.slice(39, 42)
            }
          />
          {/* <div className="md:tw-block tw-hidden">
        
        </div> */}
          <div className="mobile-view"></div>
          <div
            className="tw-text-3xl lg:tw-hidden tw-pt-2 tw-z-50"
            onClick={() => setOpen(!open1)}
          >
            {open1 ? (
              <MdOutlineClose color="black" />
            ) : (
              <MdMenu color="white" />
            )}
          </div>

          {/* Mobile nav */}
          <div
            className={`
            lg:tw-hidden   tw-z-20  tw-shadow-xl tw-bg-white  tw-fixed tw-w-full tw-h-[100vh] tw-top-0 tw-overflow-y-auto tw-bottom-0 tw-leading-10 tw-py-10 
            tw-duration-500 ${open1 ? "tw-left-0" : "  tw-left-[-100%]"}
          `}
          >
            <div className="tw-pb-5 tw-px-8 ">
              <img
                src={require("../../assets/images/logo.png")}
                width={80}
                className="tw-object-contain "
                alt="Logo"
              />
            </div>

            <ul className="tw-p-0 tw-relative tw-px-9 tw-pt-3 tw-border-t">
              <li>
                <Link className=" tw-text-black" to={"/"}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  onClick={() => {
                    setOpen(false);
                    handleNavigate("/", "aboutSection");
                  }}
                  className="tw-text-black"
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  onClick={() => {
                    setOpen(false);
                    handleNavigate("/", "tokenSection");
                  }}
                  className="tw-text-black"
                >
                  Tokenomics
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  onClick={() => {
                    setOpen(false);
                    handleNavigate("/", "roadSection");
                  }}
                  className="tw-text-black"
                >
                  Road Map
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  onClick={() => {
                    setOpen(false);
                    handleNavigate("/", "teamSection");
                  }}
                  className="tw-text-black"
                >
                  Team
                </Link>
              </li>
              <li>
                <Link to={"/staking"} className="tw-text-black">
                  Staking
                </Link>
              </li>
              <li>
                <a href={"https://mlm.tikdogs.com/"} className="tw-text-black">
                  MLM
                </a>
              </li>
              <li>
                <Button
                  onClick={() => open()}
                  className={"mobile-connect-wallet-btn tw-rounded-[30px]"}
                  label={
                    !isConnected
                      ? "Connect Wallet"
                      : address.slice(0, 4) + "...." + address.slice(39, 42)
                  }
                />
              </li>

              {/* <li>
              <Link
                to={"/"}
                onClick={() => {
                  handleNavigate("/", "teamSection");

                  setOpen(false);
                }}
                className="tw-text-white"
              >
                Team
              </Link>
            </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
