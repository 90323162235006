import React from "react";
import VideoSlider from "../videoSlider";
import buyimg from "../../assets/images/card1.svg";
import buyimg1 from "../../assets/images/card2.svg";
import buyimg2 from "../../assets/images/card3.svg";

import VideoPlayer from "../videoPlayer";
import { useAccount } from "wagmi";
import Button from "../Button";
import { useWeb3Modal } from "@web3modal/wagmi/react";
const HowToBuy = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();

  return (
    <div className="main-how-buy">
      <div className="buy-head">
        <h4 className="h-text-1">How To Buy</h4>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="how-buy">
              <div className="card">
                <img
                  src={buyimg}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <Button
                      className=" tw-rounded-[30px]  connect-wallet-btn"
                      onClick={() => open()}
                      label={
                        !isConnected
                          ? "Connect Wallet"
                          : address.slice(0, 4) + "...." + address.slice(39, 42)
                      }
                    />
                  </h5>
                  <p className="card-text">
                    To purchase TIKS Token you need to connect your wallet,
                    which involves approving the contract on your wallet
                  </p>
                </div>
              </div>

              <div className="card">
                <img
                  src={buyimg1}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body w-100">
                  <h5 className="card-title">Select payment Type</h5>
                  <p className="card-text mb-3">
                    Select from our available MATIC & USDT for payment
                  </p>
                </div>
              </div>

              <div className="card">
                <img
                  src={buyimg2}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body w-100">
                  <h5 className="card-title">Buy TIKS Token</h5>
                  <p className="card-text">
                    Simply input your amount, select buy and approve the payment
                    on your wallet
                  </p>
                </div>
              </div>
            </div>
            <div className="buy-aps-div">
              {/* <Button className='btn-buy-aps'>Buy APS NOW!!</Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowToBuy;
