import React from "react";
// import footerborder from '../assets/images/footerborder.svg'
import logo from "../../assets/images/tikdogslogo.svg";
import twit from "../../assets/images/twit.svg";
import tele from "../../assets/images/tele.svg";
import insta from "../../assets/images/insta.svg";
import youtube from "../../assets/images/youtube.svg";
import jok from "../../assets/images/jok.svg";
import discord from "../../assets/images/discord.svg";
import tiktok from "../../assets/images/tiktok.svg";
import biggradient from "../../assets/images/big-gradient.svg";
import { useAccount } from "wagmi";
import Button from "../Button";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const Footer = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();
  return (
    <div>
      {/* <div className=" tw-overflow-x-hidden ">
        <div className="container tw-py-10">
          <div className="row tw-items-center"> */}
      <div className="footer">
        <div className="footer-content">
          <div className="footer-img-main-div">
            <div className="container position-relative">
              <div className="row">
                <div className="col">
                  <div className="desktop-view">
                    <div className="main-social-div">
                      <div>
                        <img
                          src={logo}
                          width={72}
                          height={74}
                          alt="apesrunlogo"
                          title="this is the main logo for apesrun"
                        />
                      </div>
                      <div className="social-div">
                        <div>
                          <p className="follow mb-0">Follow us</p>
                        </div>
                        <a href="https://x.com/TIKDOGS_" target="blank">
                          <div>
                            <img
                              src={twit}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://t.me/tikdogs_announcements">
                          <div>
                            <img
                              src={tele}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://www.instagram.com/thetikdogs/">
                          <div>
                            <img
                              src={insta}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://www.youtube.com/@TheTikDogs">
                          <div>
                            <img
                              src={youtube}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <div>
                          <img
                            src={jok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={discord}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={tiktok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                      </div>

                      <div>
                        <Button
                          className=" tw-rounded-[30px]  connect-wallet-btn"
                          onClick={() => open()}
                          label={
                            !isConnected
                              ? "Connect Wallet"
                              : address.slice(0, 4) +
                                "...." +
                                address.slice(39, 42)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mobile-view">
                    <div className="main-social-div">
                      <div className="mob-footer mt-3">
                        <div>
                          <img
                            src={logo}
                            width={72}
                            height={74}
                            alt="apesrunlogo"
                            title="this is the main logo for apesrun"
                          />
                        </div>
                        <div>
                          <Button
                            className=" tw-rounded-[30px]  connect-wallet-btn"
                            onClick={() => open()}
                            label={
                              !isConnected
                                ? "Connect Wallet"
                                : address.slice(0, 4) +
                                  "...." +
                                  address.slice(39, 42)
                            }
                          />
                        </div>
                      </div>

                      <div className="social-div mt-3 mb-2">
                        <div>
                          <p className="follow mb-0">Follow us</p>
                        </div>
                        <a href="https://x.com/TIKDOGS_" target="blank">
                          <div>
                            <img
                              src={twit}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://t.me/tikdogs_announcements">
                          <div>
                            <img
                              src={tele}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://www.instagram.com/thetikdogs/">
                          <div>
                            <img
                              src={insta}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a href="https://www.youtube.com/@TheTikDogs">
                          <div>
                            <img
                              src={youtube}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <div>
                          <img
                            src={jok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={discord}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={tiktok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="copy-right-div">
                    <p className="mb-0">© Tikdogs2024</p>
                  </div>
                </div>
              </div>
              <div className="left-gradient">
                <img
                  src={biggradient}
                  width={904}
                  height={904}
                  alt="gradient"
                  className="gradient-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //     </div>
    //   </div>

    // </div>
  );
};

export default Footer;
