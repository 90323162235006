
import React, { useState } from 'react'
import ArtCard from '../Common/NFT Card/ArtCard';
import biggradient from '../../assets/images/big-gradient.svg'

const OurNFT = () => {
    const [activeTab, setActiveTab] = useState("Art");

    // Content for each tab
    // const tabContent = {
    //     Art: <ArtCard/>,
    //     Collectibles: <ArtCard/>,
    //     Metaverse: <ArtCard/>,
    //     "Virtual Worlds": <ArtCard/>,
    //     Sports: <ArtCard/>,
    //     Music: <ArtCard/>,
    // };

    return (
        <div className="our-nft position-relative" id="teamSection">
            <div className='container'>
        <div className='row'>
          <div className='col'>
        <div className="nft-head">
            <h4 className='h-text-1'>OUR NFTS</h4>
        </div>

        {/* Tabs */}
        {/* <div className='desktop-view'>
        <div className="tab-btn-div">
            {Object.keys(tabContent).map((tab) => (
                <button
                    key={tab}
                    className={`btn-tabs ${activeTab === tab ? "active-tab" : ""}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
        </div>

        <div className='mobile-view'>
        
            <div className="row">
            {Object.keys(tabContent).map((tab) => (
            <div className='col-6'>
                <button
                    key={tab}
                    className={`btn-tabs ${activeTab === tab ? "active-tab" : ""}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
                </div>
            ))}

        </div>
        </div> */}
        {/* Tab Content */}
        <div className="tab-content">
            {/* <p>{tabContent[activeTab]}</p> */}
            <ArtCard/>
        </div>

       {/* <div className='see-more-div'>
        <button className='see-more-btn'>see more</button>
       </div> */}
       </div>
        </div>
      </div>
      <div className="left-gradient">
                    <img src={biggradient} width={904} height={904} alt="gradient" className="gradient-img"/>
                  </div>
                  <div className="right-gradient">
                    <img src={biggradient} width={904} height={904} alt="gradient" className="gradient-img"/>
                  </div>
      </div>
    )
}

export default OurNFT
