import ustdicon from "../../../assets/images/ustdicon.svg";
import logo from "../../../assets/images/tikdogslogo.svg";
import { IoIosCopy } from "react-icons/io";
import React, { useEffect, useState } from "react";

import Decimal from "decimal.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation } from "react-router-dom";

import Web3 from "web3";
import {
  usdt_address,
  token_abi,
  presale_address,
  presale_abi,
} from "../../../configs/Contracts";

import { useWeb3Modal } from "@web3modal/wagmi/react";

import { useSwitchChain, useAccount, useDisconnect } from "wagmi";

import {
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";
import Counter from "../../Counter";

const FormBanner = ({ props }) => {
  console.log("props", props);
  const timeData = [
    { value: 51, label: "Days" },
    { value: 5, label: "Hours" },
    { value: 11, label: "Minutes" },
    { value: 52, label: "Seconds" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [choosed_statement, set_choosed_statement] = useState(0);

  const openModal = (val) => {
    set_choosed_statement(val);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const chainId =
    process.env.REACT_APP_ENV == "production" ? polygon.id : polygonAmoy.id;
  const [selectedCurrency, setSelectedCurrency] = useState("usdt");

  const link_notify = () => toast("Referral Link Copied!");

  const [payAmount, set_payAmount] = useState(0);
  const [receiveAmount, set_receiveAmount] = useState(0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { open, close } = useWeb3Modal();
  const [count, set_count] = useState(0);
  const { address, isConnecting, isDisconnected } = useAccount();

  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    if (payAmount != "") {
      onPay(payAmount);
    }
  }, [selectedCurrency, selectedButton]);

  const notify = () => toast("Token Purchased Successfully!");

  const handleSelect = (currency) => {
    setSelectedCurrency(currency);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getBorderColor = (currency) => {
    return selectedCurrency === currency
      ? "tw-border-[#00F0FF] tw-border-2"
      : "tw-border-[#456DA7]";
  };

  const handleBSelect = (button) => {
    setSelectedButton(button);
  };

  const getBBorderColor = (button) => {
    return selectedButton === button
      ? "tw-border-[#00F0FF] tw-border-2"
      : "tw-border-white";
  };

  const openPdfInNewTab = () => {
    const pdfUrl = require("../../../assets/images/whitepaper.pdf");
    window.open(pdfUrl, "_blank");
  };

  const [openVideo, setOpenVideo] = useState(false);

  const [prog_percentage, set_prog_percentage] = useState(0);
  const [ref_add, set_ref] = useState(
    "0x0000000000000000000000000000000000000000"
  );

  const { switchChainAsync } = useSwitchChain();
  const { chainId: currentChainId } = useAccount();
  const {
    writeContractAsync,
    writeContract,
    data: hash,
    ...states
  } = useWriteContract();

  useEffect(() => {
    if (params.get("ref") != null) {
      set_ref(params.get("ref"));
    }

    setpercantage();
    onPay(0);
  }, [props?.curr_presale?.endTime]);

  function setpercantage() {
    set_prog_percentage(
      (Number(props?.curr_presale?.total_sold) /
        10 ** 18 /
        (Number(props?.curr_presale?.supply) / 10 ** 18)) *
        100
    );
  }

  async function buytoken1() {
    try {
      const tx = await writeContractAsync({
        abi: presale_abi,
        address: presale_address,
        functionName: "buy_token",
        args: [
          Convert_To_Wei(payAmount ? Number(payAmount) : 0),
          ref_add,
          selectedCurrency == "POL" ? "0" : "1",
        ],
        value:
          selectedCurrency == "POL"
            ? Convert_To_Wei(payAmount ? Number(payAmount) : "0")
            : 0,
      });

      set_count(1);
    } catch (err) {
      console.error(err);
    }
  }

  async function usdt_approval() {
    try {
      const tx = await writeContractAsync({
        abi: token_abi,
        address: usdt_address,
        args: [presale_address, payAmount ? Number(payAmount) * 10 ** 6 : "0"],
        functionName: "approve",
      });
    } catch (err) {
      console.error(err);
    }
  }

  function Convert_To_eth(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon.meowrpc.com")
    );

    val = web3.utils.fromWei(val.toString(), "ether");
    return val;
  }

  function Convert_To_Wei(val) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon.meowrpc.com")
    );

    val = web3.utils.toWei(val.toString(), "ether");
    return val;
  }

  function onPay(value) {
    if (value == "" || value == 0) {
      set_receiveAmount(0);
      // set_payAmount(0)

      return;
    }

    let price;
    if (selectedCurrency == "usdt" || selectedCurrency == "USDC") {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = value.div(dec_price);

    set_receiveAmount(Number(temp).toFixed(2));
  }

  function onRecieve(value) {
    if (value == "" || value == 0) {
      set_payAmount(0);

      return;
    }
    let price;
    if (selectedCurrency == "usdt" || selectedCurrency == "USDC") {
      price = Number(props.curr_presale.price) / 10 ** 18;
    } else {
      price = Number(props.perTokenIn_Matic) / 10 ** 18;
    }

    let dec_price = new Decimal(price);
    value = new Decimal(value);
    let temp = dec_price.times(value);

    set_payAmount(Number(temp).toFixed(2));
  }

  async function buy_token(choosed_option) {
    console.log("calling");
    if (isDisconnected) {
      alert("Kindly connect your wallet");
      return;
    }
    if (payAmount == "" || payAmount == "0") {
      alert("Kidly write the amount");
      return;
    }

    if (selectedCurrency == "POL") {
      if (Number(props.MATICBalance) < Number(Convert_To_Wei(payAmount))) {
        alert("You don't have enough Matic");
        return;
      }

      // if (Number(props.minPurchase_matic) / 10 ** 18 > Number(payAmount)) {
      //   alert(
      //     "Minimum purchase is " +
      //       (Number(props.minPurchase_matic) / 10 ** 18).toFixed(1) +
      //       " MATIC"
      //   );
      //   return;
      // }
      if (Number(props.minPurchase_matic) > Number(payAmount)) {
        alert(
          "Minimum purchase is " + Number(props.minPurchase_matic) + " MATIC"
        );
        return;
      }
      console.log("chainId != currentChainId", chainId != currentChainId);
      if (chainId != currentChainId) {
        await switchChainAsync({ chainId });
        await buytoken1?.();
      } else {
        await buytoken1?.();
      }
    } else if (selectedCurrency == "usdt") {
      if (Number(props.USDTBalance) < Number(payAmount) * 10 ** 6) {
        alert("You don't have enough USDT");
        return;
      }
      if (Number(props.min_purchase) / 10 ** 18 > Number(payAmount)) {
        alert(
          "Minimum purchase is " +
            Number(props.min_purchase) / 10 ** 18 +
            " USDT"
        );
        return;
      }
      if (chainId != currentChainId) {
        await switchChainAsync({ chainId });
        await usdt_approval?.();
      } else {
        await usdt_approval?.();
      }
    }
  }
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (isConfirmed) {
      // alert(count)
      if (count == 0) {
        // set_count(1)
        buytoken1();
      }
      if (count == 1) {
        set_count(0);
        notify();
        set_payAmount(0);
        set_receiveAmount(0);
        props.test();
      }
    }
  }, [isConfirmed]);
  return (
    <div>
      <div className="main-form-div  ">
        <div className="form-div">
          <div className="timing-div">
            <Counter
              time={
                props.curr_presale.endTime
                  ? Number(props.curr_presale.endTime)
                  : 0
              }
            />
            <div className="price-div">
              <h4>
                Next Price <span>Increase in</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="main-stage-form-div">
          <div className="stage-form-div">
            <div>
              <h4>Stage {Number(props.curr_stage) + 1}</h4>
              <p className="stage-raised">
                Raised : ${" "}
                {props.curr_presale.supply
                  ? (
                      ((Number(props.curr_presale.price) / 10 ** 18) *
                        Number(props.curr_presale.total_sold)) /
                      10 ** 18
                    ).toFixed(2)
                  : 0}
              </p>
            </div>

            <div>
              <h4>
                $
                {Number(props.NextStagePrice)
                  ? Convert_To_eth(props.NextStagePrice)
                  : ""}{" "}
              </h4>
              <p>+12.45%</p>
            </div>
          </div>
        </div>

        <div className="form-section">
          {/* <div className="form-progress-div">
          <div
            className="progress w-100"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100">
            <div className="progress-bar w-75"></div>
          </div>
        </div> */}
          <div className="bal-section">
            <div className="bal-input-section">
              <div>
                <p className="mb-0">
                  Balance:{" "}
                  {selectedCurrency === "POL"
                    ? props.MATICBalance
                      ? (Number(props.MATICBalance) / 10 ** 18).toFixed(3)
                      : "0"
                    : props.USDTBalance
                    ? (Number(props.USDTBalance) / 10 ** 6).toFixed(2)
                    : "0"}
                </p>
              </div>
              <div className="desktop-view">
                <div className="d-flex align-items-start gap-2">
                  {selectedCurrency === "usdt" ? (
                    <img
                      src={require("../../../assets/images/usdt.png")}
                      width={18}
                      height={18}
                    />
                  ) : (
                    <img
                      src={require("../../../assets/images/c2.png")}
                      width={18}
                      height={18}
                    />
                  )}
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="enter"
                      disabled={props.perTokenIn_Matic > 0 ? false : true}
                      min={0}
                      value={payAmount}
                      onChange={(e) => {
                        set_payAmount(e.target.value);
                        onPay(e.target.value);
                      }}
                    />
                  </div>
                  <div className="drop-section">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle d-flex align-items-center gap-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {/* <img
                          src={selectedCurrency == "USDT" ? ustdicon : ""}
                          width={18}
                          height={18}
                          alt="ustd"
                          title="ustd icon"
                        /> */}
                        {selectedCurrency === "usdt" ? (
                          <img
                            src={require("../../../assets/images/usdt.png")}
                            width={18}
                            height={18}
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/c2.png")}
                            width={18}
                            height={18}
                          />
                        )}
                        {selectedCurrency}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => handleSelect("usdt")}>
                            USDT
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => handleSelect("POL")}>
                            POL
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-view">
                <div className="d-flex  gap-2 justify-content-start  align-items-center">
                  <div className="w-100 d-flex gap-2">
                    {selectedCurrency === "usdt" ? (
                      <img
                        src={require("../../../assets/images/usdt.png")}
                        className=" tw-w-6 tw-h-8 tw-pt-2"
                      />
                    ) : (
                      <img
                        src={require("../../../assets/images/c2.png")}
                        className=" tw-w-8 tw-h-8"
                      />
                    )}
                    <input
                      type="text"
                      className="form-control ustd-form-control"
                      id="exampleFormControlInput1"
                      placeholder="enter"
                      disabled={props.perTokenIn_Matic > 0 ? false : true}
                      min={0}
                      value={payAmount}
                      onChange={(e) => {
                        set_payAmount(e.target.value);
                        onPay(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="drop-btn-form">
                  <div className="drop-section">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle d-flex align-items-center gap-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {/* <img
                          src={selectedCurrency == "USDT" ? ustdicon : ""}
                          width={18}
                          height={18}
                          alt="ustd"
                          title="ustd icon"
                        /> */}
                        {selectedCurrency === "USDT" ? (
                          <img
                            src={require("../../../assets/images/usdt.png")}
                            width={18}
                            height={18}
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/c2.png")}
                            width={18}
                            height={18}
                          />
                        )}
                        {selectedCurrency}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => handleSelect("usdt")}>
                            USDT
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => handleSelect("POL")}>
                            POL
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bal-section value-showing-div">
            <div className="desktop-view">
              <div className="bal-input-section">
                <div>
                  <p>Tiks Receive</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={logo}
                    width={20}
                    height={20}
                    alt="ustd"
                    title="ustd icon"
                  />
                  <div>
                    <p>{receiveAmount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view">
              <div className="bal-input-section">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Tiks Receive</p>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={logo}
                      width={20}
                      height={20}
                      alt="ustd"
                      title="ustd icon"
                    />

                    <p>{receiveAmount}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bal-input-section">
              <p className="next-value">
                Next Increase value = $
                {Number(props.NextStagePrice)
                  ? Convert_To_eth(props.NextStagePrice)
                  : ""}{" "}
              </p>
              <p className="aps-value">
                1 Tiks = $
                {props.curr_presale.price
                  ? Convert_To_eth(props.curr_presale.price)
                  : ""}{" "}
              </p>
            </div>
          </div>

          {/* <div className="bal-section refer-section w-100 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                <p>Ref Link</p>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <p className="refer-value">xnkdnb klnclknbkfnnmkmb kmfbxdf</p>
                </div>
                <div>
                  <IoIosCopy className="text-white" />
                </div>
              </div>
            </div>
          </div> */}

          <div className="banner-btn w-100">
            {isDisconnected ? (
              <button
                onClick={() => open()}
                label={"Connect Wallet"}
                className={"connect-wallet"}>
                Connect Wallet
              </button>
            ) : (
              <>
                <button
                  onClick={() => buy_token()}
                  className={"connect-wallet"}>
                  Buy Now
                </button>
                <button
                  onClick={() => open()}
                  className={"connect-wallet mt-2"}>
                  Disconnect
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBanner;
