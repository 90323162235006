import React from "react";
import biggradient from "../../assets/images/big-gradient.svg";

const faqData = [
  {
    id: 1,
    question: "What is TikDogs and the TIKS token all about?",
    answer:
      "TikDogs is a unique meme token project that combines community, rewards, and entertainment centered around dogs. The TIKS token is the cryptocurrency that powers TikDogs, a TikTok-inspired platform where users can share short videos exclusively featuring dogs. By holding TIKS, users gain access to the TikDogs app, where they earn rewards for every like and share their videos receive. TIKS also has an MLM (Multi-Level Marketing) system designed as a fundraising method to support the development of the TikDogs platform.",
  },
  {
    id: 2,
    question: "How does the MLM system work on TikDogs?",
    answer:
      " TikDogs includes an MLM system that rewards users for referring others to buy TIKS and join the platform. This MLM system is powered by AI software, which helps active participants generate significant income. By staying active in the MLM program, users can increase their earnings to a level where some may not need to work traditional jobs anymore. To learn more about how the MLM system works, please refer to our PDF guide [link here].",
  },
  {
    id: 3,
    question: "How does the TIKS token price increase?",
    answer:
      "The TIKS token has a price-increase mechanism powered by blockchain technology, designed to respond to demand through a smart contract algorithm. As more people buy TIKS, the algorithm automatically adjusts the token price upward, rewarding early adopters and supporting a stable value increase over time. This automated demand-based pricing system benefits holders as the platform grows in popularity. ",
  },
  {
    id: 4,
    question: "How does TikDogs plan to generate additional income through advertising?",
    answer:
      "TikDogs has strong growth potential, not only from user activity but also from advertising revenue. With the global pet industry valued at over $200 billion, TikDogs is ideally positioned to attract major advertisers, such as pet supply brands and pet services, seeking to reach a dedicated audience of pet owners. As the platform grows, advertising revenue from pet suppliers and related industries will create additional income opportunities, further supporting the platform and potentially increasing TIKS token value.",
  },
  {
    id: 5,
    question: "What is the potential audience size for TikDogs?",
    answer:
      " The potential audience for TikDogs is enormous. In the United States alone, approximately 69 million households own a dog, translating to about 54% of all households. Globally, there are over 600 million pet dogs, with dog ownership varying by country. This large and engaged audience of dog owners represents a unique market for TikDogs, both for platform growth and for attracting advertising partners. If you have a specific region or country in mind, we can provide more tailored information!",
  },
  {
    id: 6,
    question: "What is the TikDogs platform?",
    answer:
      " TikDogs, found at TikDogs.com, is a short video-sharing platform available as an app for iOS and Android users. Inspired by TikTok, TikDogs is exclusively for dog content, allowing users to upload short videos of their dogs, gain followers, and interact with other dog lovers. Content creators earn TIKS tokens for every like, share, and engagement their posts receive, making it a rewarding platform for dog enthusiasts.",
  },
  {
    id: 7,
    question: "How do I get paid for likes and shares on TikDogs?",
    answer:
      "When someone likes or shares your video on TikDogs, you earn TIKS tokens. The more popular your content is, the more you earn. This feature incentivizes quality content and active engagement among dog lovers.",
  },
  {
    id: 8,
    question: "Can I redeem TIKS tokens for other currencies?",
    answer:
      " Yes, TIKS tokens can be redeemed for USDT, allowing users to convert their rewards into a stable cryptocurrency. This makes it easy for users to cash out their earnings or reinvest them.",
  },
  {
    id: 9,
    question: " How do I buy TIKS tokens?",
    answer:
      " You can purchase TIKS on our website, TikDogs.com, or through popular decentralized exchanges. You’ll need a compatible wallet, such as MetaMask, to hold your TIKS tokens. Once you’ve purchased TIKS, you can start referring others, sharing content, and earning rewards on TikDogs.",
  },
  {
    id: 10,
    question: "How do I start sharing content on TikDogs?",
    answer:
      " Once you hold TIKS tokens, you’ll gain access to the TikDogs app on iOS or Android. Download the app, create a profile, upload short videos of your dog, and start engaging with the community. You’ll automatically earn rewards for every like, comment, or share your posts receive.",
  },
];

const FAQ = () => {
  return (
    <div className="faq position-relative">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="faq-head">
              <h4 className="h-text-1">
                Find answers to
                <br className="break" />
                common questions.
              </h4>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {faqData.map((item) => (
                <div className="accordion-item" key={item.id}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${item.id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${item.id}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${item.id}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="left-gradient">
        <img
          src={biggradient}
          width={904}
          height={904}
          alt="gradient"
          className="gradient-img"
        />
      </div>
      <div className="right-gradient">
        <img
          src={biggradient}
          width={904}
          height={904}
          alt="gradient"
          className="gradient-img"
        />
      </div>
    </div>
  );
};

export default FAQ;
