import React from 'react'
import Button from '../Button'
import abtvideo from './assets/aboutvideo.mp4'
import VissionMission from '../VissionMission/VissionMission';
import biggradient from '../../assets/images/big-gradient.svg'


// import EBM_Avenue from '../EBM_avenue'
const About = () => {


  const openPdfInNewTab = () => {
    const pdfUrl = require("../../assets/images/whitepaper.pdf");
    window.open(pdfUrl, "_blank");
  };


  return (
    <div  id='aboutSection'  className='abt-sec  tw-relative tw-py-[2rem]  tw-w-full tw-h-auto'>
          

      <div className='about position-relative'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                    <div className="banner-container">
                <h1 className="h-text-1">About us</h1>
                <h2 className="h-text-2">Everyone encounters difficulties</h2>
                <p className="p-text-1">
                    At TIKDOGS, we harness the power of AI and blockchain to revolutionize pet ownership. Our platform enables dogs to earn “TIKS” through engaging content, ensuring transparent rewards. By connecting owners and creating a vibrant community, we empower pets to contribute to their happiness in a fun, secure environment!
                </p>

            </div>
            <div className='about-video-div'>
                <video className="abt-video" playsInline autoPlay loop muted>
                    <source src={abtvideo} type="video/mp4" />
                </video>
            </div>
            <div className='btn-div'>
            <button className='whitepaper-btn' onClick={openPdfInNewTab}>Whitepaper</button>
            </div>
                    </div>
                </div>
        
            </div>
            <div className="left-gradient">
                    <img src={biggradient} width={352} height={352} alt="gradient" className="gradient-img"/>
                  </div>
        </div>

<VissionMission/>
         
      </div>
     

     

  )
}

export default About